// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import 'boxicons/css/boxicons.min.css';

// import sass
import './scss/main';


// import js

// import './vendor/php-email-form/validate';

import './js/animations.js';
import './js/functions.js';


